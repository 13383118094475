import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { importPageComponent } from "@/scripts/vite/import-page-component";
import Vue3SmoothScroll from "vue3-smooth-scroll";
import * as Sentry from "@sentry/vue";
import "@/scss/style.scss";
// MEMO: .scss のファイルは '@/' prefix でここに↑の様に書いておけば、Vite がコンパイルして全てのページにインポートしてくれます。

createInertiaApp({
    resolve: (name) =>
        importPageComponent(name, import.meta.glob("../views/pages/**/*.vue")),
    setup({ el, app, props, plugin }) {
        const vueApp = createApp({ render: () => h(app, props) })
            // .directive('smooth-scroll', Vue3SmoothScroll)
            .use(plugin)
            .use(Vue3SmoothScroll, {
                duration: 500, // アニメーションにかかる時間(ms)
                offset: 0, // スクロールするオフセット(マイナスも可)
                updateHistory: true, // ハッシュを履歴に残すかどうか(よくわからん)
            });

        // @ts-ignore
        if (JSON.parse(el.dataset.page).props.sentry_front_dsn) {
            Sentry.init({
                app: vueApp,
                // @ts-ignore
                dsn: JSON.parse(el.dataset.page).props.sentry_front_dsn,
                integrations: [new Sentry.BrowserTracing()],
                tracesSampleRate: 0.0,
            });
        }

        vueApp.mount(el);
    },
});
